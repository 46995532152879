.experience {
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.experience h2 {
  font-size: 2rem;
  color: #61dafb;
  margin-bottom: 20px;
  text-align: center;
}

.experience ul {
  list-style-type: none;
  padding: 0;
}

.experience li {
  background-color: #333;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.experience li strong {
  color: #61dafb;
  font-size: 1.3rem;
}

.section-icon {
  margin-right: 10px;
  color: #61dafb;
  font-size: 1.5rem;
}

/* Estilo para los enlaces de proyectos en la experiencia */
.project-link {
  color: #ffffff;
  text-decoration: none;
}

.project-link:hover {
  color: #61dafb;
  text-decoration: underline;
}
