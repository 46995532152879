.github-section {
  padding: 20px;
  background-color: #2c2c2c; /* Consistente con el resto de secciones */
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.github-section h2 {
  font-size: 2rem;
  color: #61dafb; /* Color del título */
  margin-bottom: 20px;
}

.github-link {
  color: #61dafb; /* Color de los enlaces */
  font-size: 1.2rem;
  text-decoration: none;
}

.github-link:hover {
  color: #f0f0f0;
  text-decoration: underline;
}
