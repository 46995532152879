.skills {
  padding: 20px;
  background-color: #2c2c2c; /* Fondo uniforme */
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Caja con sombra */
}

.skills h2 {
  font-size: 2rem;
  color: #61dafb;
  margin-bottom: 20px;
  text-align: center;
}

.skills ul {
  list-style-type: none;
  padding: 0;
}

.skills li {
  background-color: #333; /* Fondo de cada ítem de la lista */
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Caja con sombra */
}

.skills li::before {
  content: '•';
  color: #61dafb;
  font-size: 1.5rem;
  margin-right: 10px;
}

.section-icon {
  margin-right: 10px;
  color: #61dafb;
  font-size: 1.5rem;
}
