.education {
  padding: 20px;
  background-color: #2c2c2c; /* Fondo oscuro para uniformidad */
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra para las cajas */
}

.education h2 {
  font-size: 2rem;
  color: #61dafb;
  margin-bottom: 20px;
  text-align: center;
}

.education ul {
  list-style-type: none;
  padding: 0;
}

.education li {
  background-color: #333; /* Color de fondo para cada item */
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Caja con sombra */
}

.education li:hover {
  background-color: #444; /* Cambio de color al pasar el mouse */
}

.education li strong {
  color: #61dafb;
  font-size: 1.3rem;
}

.education li p {
  color: #e0e0e0;
  margin-top: 5px;
}

.section-icon {
  margin-right: 10px;
  color: #61dafb;
  font-size: 1.5rem;
}

.caret-icon {
  cursor: pointer;
  color: #61dafb;
  font-size: 1.2rem;
  margin-left: 10px;
}

.certificate-image {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.certificate-image img {
  max-width: 100%;
  height: auto; /* Mantener la proporción */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
