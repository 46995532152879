.projects {
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.projects h2 {
  font-size: 2rem;
  color: #61dafb;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-list {
  list-style: none;
  padding: 0;
}

.project-list li {
  font-size: 1.2rem;
  margin-bottom: 10px;
  position: relative;
}

.project-list a,
.project-title {
  color: #61dafb;
  text-decoration: none;
}

.project-list a:hover,
.project-title:hover {
  color: #61dafb;
}

.details-toggle {
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 12px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
  color: #f0f0f0;
}

.project-details,
.sub-project-details {
  margin-top: 10px;
  padding: 15px;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #f0f0f0;
  text-align: justify;
}

/* Estilos para las imágenes dentro de proyectos */
.project-images, {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-images img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Estilos para las imágenes dentro de proyectos */
.project-images,
.sub-project-details img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.project-item {
  margin-bottom: 1px; /* Ajusta este valor para ganar más espacio */
  padding-top: 20px; /* Si quieres añadir más separación interna */
}

.project-list li {
  margin-bottom: 20px; /* Espaciado entre el título y los subproyectos */
}

.project-button {
  background-color: #333;
  border-radius: 8px;
  border: none;
  color: #f0f0f0;
  cursor: pointer;
  font-size: 1.2rem;
  text-decoration: none;
  box-shadow: 0 12px 8px rgba(0, 0, 0, 0.2);
}

.project-button:hover {
  color: #61dafb;
}

/* Centrar el enlace "Toca aquí para visitar" */
.visit-link {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 30px;
}

/* Contenedor responsivo para el iframe */
.video-container {
  position: relative;
  width: 100%; /* Ocupará todo el ancho disponible */
  padding-top: 56.25%; /* Relación de aspecto 16:9 (altura basada en el ancho) */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Estilo para el iframe dentro del contenedor */
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ajuste completo al ancho del contenedor */
  height: 100%; /* Ajuste completo a la altura calculada */
  border: none;
}
