/* Activar desplazamiento suave en todo el documento */
html {
  scroll-behavior: smooth;
}

/* Estilos generales */
body {
  background-color: #1e1e1e; /* Color oscuro de fondo para el tema */
  color: #f0f0f0; /* Color del texto */
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.App {
  text-align: center;
  padding-top: 30px; /* Añade espacio para que la cabecera no cubra el contenido */
  padding-bottom: 60px; /* Añade espacio para el footer */
}

.App-logo {
  height: 100px;
  margin-top: 0px; /* Ajusta el margen superior para el logo */
}

.navbar {
  height: 15px; /* Asegúrate de que la altura de la barra de navegación esté definida */
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  z-index: 1000;
}


/* Estilos de la cabecera */
.App-header {
  background-color: #282c34;
  padding: 20px;
  color: #f0f0f0;
  position: fixed; /* Cabecera fija */
  top: 0;
  width: 100%;
  z-index: 1000;
}

.App-footer {
  background-color: #333;
  color: #f0f0f0;
  padding: 20px;
  position: fixed; /* Footer fijo */
  bottom: 0;
  width: 100%;
  text-align: center;
}

/* Imagen de fondo aplicada al cuerpo */
.background-container {
  background-image: url('./assets/fondo.png');
  background-size: cover;
  background-position: center;
  padding: 50px 20px; /* Ajustamos el espacio para las secciones */
  margin-top: 0px; /* Ajuste para acercar el cuerpo a la cabecera */
}

/* Justificar el texto en las secciones */




/* Ajustes responsivos */
@media (max-width: 768px) {
  .App-logo {
    height: 100px; /* Reducción del tamaño del logo en móviles */
    margin-top: 10px;
  }

  .App-header {
    padding: 10px;
  }
}
