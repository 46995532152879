.courses {
  padding: 20px;
  background-color: #2c2c2c; /* Fondo uniforme con las otras secciones */
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Caja con sombra */
}

.courses h2 {
  font-size: 2rem;
  color: #61dafb;
  margin-bottom: 20px;
  text-align: center;
}

.courses ul {
  list-style-type: none;
  padding: 0;
}

.courses li:hover {
  background-color: #444; /* Cambio de color al pasar el mouse */
}


.courses li {
  background-color: #333; /* Fondo de cada ítem de la lista */
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Caja con sombra */
}

.courses li strong {
  color: #61dafb;
  font-size: 1.3rem;
}

.courses li p {
  color: #e0e0e0;
  margin-top: 5px;
}

.section-icon {
  margin-right: 10px;
  color: #61dafb;
  font-size: 1.5rem;
}

.course-item {
  margin-bottom: 20px;
}

.caret-icon {
  cursor: pointer;
  color: #61dafb;
  font-size: 1.2rem;
  margin-left: 10px;
}

.certificate-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.certificate-image img {
  max-width: 100%; /* La imagen ocupará todo el ancho del contenedor */
  margin-bottom: 15px; /* Espacio entre las imágenes */
  height: auto; /* Mantener proporción */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}