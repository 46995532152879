.courses-fit {
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.courses-fit h2 {
  font-size: 2rem;
  color: #61dafb;
  margin-bottom: 20px;
  text-align: center;
}

.courses-fit ul {
  list-style-type: none;
  padding: 0;
}

.courses-fit li {
  background-color: #333;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.courses-fit li strong {
  color: #61dafb;
  font-size: 1.3rem;
}

.courses-fit li p {
  color: #e0e0e0;
  margin-top: 5px;
}

.caret-icon {
  cursor: pointer;
  color: #61dafb;
  font-size: 1.2rem;
  margin-left: 10px;
}

.certificate-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.certificate-image img {
  max-width: 100%;
  margin-bottom: 15px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
