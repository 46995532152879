.contact {
  padding: 20px;
  background-color: #2c2c2c; /* Fondo oscuro como el resto de las secciones */
  border-radius: 8px;
  max-width: 800px;
  margin: 40px auto;
  color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.contact h2 {
  font-size: 2rem;
  color: #61dafb; /* Color de título */
  margin-bottom: 20px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-icon {
  font-size: 1.5rem;
  color: #61dafb; /* Color de iconos */
}

.contact-item a, .contact-item p {
  font-size: 1.2rem;
  color: #f0f0f0;
  text-decoration: none;
}

.contact-item a:hover {
  color: #61dafb;
  text-decoration: underline;
}

/* Estilos para el botón de descarga del CV */
.download-item {
  margin-top: 20px; /* Añadimos un margen superior para separar el botón */
}

.download-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #479ECE; /* Fondo azul */
  color: #F0F0F0; /* Texto oscuro */
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  gap: 20px; /* Añadimos un espacio entre el icono y el texto */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.download-btn:hover {
  background-color: #21a1f1; /* Cambio de color al pasar el ratón */
  color: white;
}

.section-icon {
  margin-right: 10px;
  color: #61dafb;
  font-size: 1.5rem;
}